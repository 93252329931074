<script>
import axios from 'axios'
import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm.js'

export default {
    data() {
        return {
            rxSelect: null,
            tooltip: null
        }
    },
    computed: {
        $form: () => document.forms['product_addtocart_form_main']
    },
    mounted() {
        const select = document.getElementById('rx-select-level')

        if (select) {
            this.showElements(false)
            const placeholder = document.getElementById('placeholder')
            const selectedOption = document.querySelector('#rx-select-level')
                .selectedIndex
            if (placeholder && selectedOption != 0) {
                placeholder.selected = true
            }
        }

        select.addEventListener('change', () => {
            const placeholder = document.getElementById('placeholder')

            select.classList.remove('select-inactive')

            if (placeholder) {
                placeholder.remove()
            }

            this.selection()
            this.tooltip?.hide()
        })

        // form validator
        if (this.$form) {
            this.$form
                .querySelector('.addtocart-btn')
                .addEventListener('click', this.rxFormValidate)
            this.$form
                .querySelector('.product-qty')
                .addEventListener('click', this.rxFormValidate)
        }
    },
    methods: {
        rxFormValidate() {
            const isRxLevelNotSelected = this.$form
                .querySelector('.product-qty')
                .classList.contains('disabled')
            if (isRxLevelNotSelected) {
                this.showRxTooltip()
            }
        },

        /* ============================================ *
         * Handler to change RX form
         * ============================================ */
        selection() {
            const $level = this?.$form?.['rx-select-level']
            if (!$level) {
                return
            }
            let level = $level.value

            this.showElements(false)

            $level.addEventListener('change', (event) => {
                window.lastSelectedRxLevel = event.currentTarget.value
                level = event.currentTarget.value
                this.getPrice(level)
                if (window.lastSelectedRxLevel === '') this.showElements(false)
            })

            if (document.querySelector('.product-rx')) {
                this.getPrice(level)
            }
        },
        /* ============================================ *
         * Get Price based on current form
         * ============================================ */
        getPrice(level) {
            const $rxPrice = this.$form.querySelector('.rx-price')
            const $priceEl = $rxPrice.querySelector('span.price')

            // show hidden elements
            if (level) {
                if (this.$form.querySelector('.product-rx')) {
                    this.showElements(false)
                }
                axios
                    .post(BASE_URL + 'base/ajax/getRxPrice', {
                        refundLevelSelected: level,
                        formKey: this.$form['form_key'].value,
                        productId: this.$form['product'].value
                    })
                    .then((response) => {
                        if (!response.data.error) {
                            $priceEl.innerText = response.data.price
                            this.showElements(true)

                            window.dataLayer.push({
                                'product-price': response.data.price
                                    .replace('zł', '')
                                    .trim()
                            })
                        } else {
                            this.showElements(false)
                        }
                    })
            } else {
                !document.querySelector(
                    '.product-view--unavailable .product-rx'
                )
                    ? this.showElements(false)
                    : null
            }
        },
        /* ============================================ *
         * Show / hidden rx elements
         * ============================================ */
        showElements(show) {
            if (
                !document.querySelector(
                    '.product-top--norefund, [data-product-out]'
                )
            ) {
                const $qty = this.$form.querySelector('.qty-form--product')
                const $qtyInput = this.$form['qty']
                const $btn = this.$form['product_addtocart_button']
                const $el = this.$form.querySelector('.rx-el.rx-price')

                if (show) {
                    $qty.classList.remove('disabled')
                    $qtyInput.disabled = false
                    $btn.disabled = false
                    this.tooltip?.dispose()
                    $el.classList.remove('rx-hide')
                    this.$form
                        .querySelector('.rx-el--price')
                        .classList.add('rx-hide')
                } else {
                    $qty.classList.add('disabled')
                    $qtyInput.disabled = true
                    $btn.disabled = true
                }
            }
        },
        showRxTooltip() {
            const $refundLevel = this.$form.querySelector('.refund-level')
            this.tooltip = new Tooltip($refundLevel, {
                template:
                    '<div class="tooltip tooltip--red" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            })
            this.tooltip.show()

            setTimeout(() => this.tooltip?.hide(), 5000)
            $refundLevel.addEventListener('hidden.bs.tooltip', () =>
                this.tooltip?.dispose()
            )
        }
    },
    render: () => null
}
</script>
